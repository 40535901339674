import React from 'react';
import ApiIcon from "../util/apiIcons";
const countries = require('country-list');

let source = process.env.REACT_APP_SOURCE || "";
let marketplace = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.finconecta.com";

const APICategory = ({ data, cardFront, clickedAPI, image }) => {
  const countryCodes = [];
  if ('apiCountries' in data && data.apiCountries.length) {
    data.apiCountries.forEach((c) => countryCodes.push(countries.getCode(c)));
  }
  
  return (
    <div className="api-container" style={clickedAPI ? { transform: 'rotateY(180deg)' } : {}}>
      <div className="card-front">
        <img
          className="api-image"
          src={`https://marketplace.dev.finconecta.com/api/logo/${image}`}
          alt={data.apiImage || 'api-image'}
        />
        <div className="api-front-data">
          <div className="api-title">
            <ApiIcon size={100} iconReference={data.apiKey} />
            <p>{data.apiName || ''}</p>
          </div>
          <p>{data.description || ''}</p>
          <div className="tags">
            <p className="stage">Sandbox</p>
            <p className="visibility">Registered</p>
          </div>
        </div>
      </div>
      <div className="card-back">
        <p className="main-title">{'name' in data? data.name: ''}</p>
        <div className="api-back-data">
          <div className="bottom-container">
            <p className="title">Certifications:</p>
            <div className="certifications">
              {data.certifications?.length ? (
                data.certifications.map((c, i) => (
                  <p key={`key_${i}`} className="certification">{c}</p>
                ))
              ) : (
                <p className="something-missing">Certifications Missing</p>
              )}
            </div>
          </div>
          <div className="register">
            <a href={`${marketplace}/fihogar/register?source=${source}`} target="_blank" rel="noopener noreferrer">
              <p className="title">Register to Review</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default APICategory;
