let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.finconecta.com"
let source  = process.env.REACT_APP_SOURCE || ""

export default {
  en: {
    mainTitle: 'START BUILDING',
    texts: [
      "It is very simple to start the process with Banco Fihogar for Developers. Our APIs have been developed to generate multiple reasons for use and create innovative business opportunities. We provide clear and simple documentation to facilitate exploration and simplify its development.",
      "Do you have any questions? We are here to answer any doubts that may arise. Please consult with our chatbot,it will be our pleasure to assist you. "
    ],
    steps: [
      {
        title: '1. Register',
        texts:[
          'Are you a new member of the portal? Registering is very simple. You just need to fill in our registration form. We will send you an email with a link to activate your account.',
          {button: 'Register', external: `${marketplace}/fihogar/register?source=${source}`},
          "Are you a member of our ecosystem? Begin your session and start editing or creating your apps.",
          {button: "Sign In", external: `${marketplace}/fihogar/login?source=${source}`}
        ]
      },
      {
        title: '2. Token Access/Secret key',
        texts:[
          'What is this about?',
          'The token is a unique and private credential that allows us to identify your app within our portal in a safe manner.',
          'The secret key is the access to documentation pertaining to our private APIs.',
          'To get access you must complete your registration and send the following documents for the signing of the confidentiality agreement (NDA) with Banco Fihogar.',
          {olist: ['Constitution record','Identification of legal representative','Proof of address','Tax registration']},
          'Once you deliver the information and document you will have access to our API documentation which you will be able to test in front of our BankBot (banking simulator).'
        ]
      },
      {
        title: '3. Subscribe to our Sandbox',
        texts:[
          'Now comes the fun part! ',
          'Our API documentation will teach you how to format HTTPS calls. Complete the required information and you will be able to start the process of building your application in a controlled environment.'
        ]
      },
      {
        title: '4. Make your first API Call',
        texts:[
          'Congratulations!',
          'When you finish the testing stage in the sandbox, our team will give you all the support you need to take the application into production'
        ]
      }
    ]
  },
  es: {
    mainTitle: 'EMPIEZA A CONSTRUIR',
    texts: [
      "Empezar a construir con Banco Fihogar para Desarrolladores es muy sencillo. Nuestras APIs han sido desarrolladas para generar múltiples  casos de uso y crear innovadoras oportunidades de negocio. Proveemos documentación clara y sencilla para facilitar la exploración y simplificar el desarrollo.",
      "¿Tienes preguntas?  Estamos listos para responder a todas tus dudas. Consulta con nuestro chatbot será un gusto poder atenderte."
    ],
    steps: [
      {
        title: '1. Registro',
        texts:[
          '¿Eres nuevo en el portal? Registrarte es muy sencillo. Solo tienes que llenar nuestro formato de registro. Te enviaremos un correo con un enlace para activar tu cuenta',
          {button: 'Registro', external: `${marketplace}/fihogar/register?source=${source}`},
          "¿Ya eres miembro de nuestro ecosistema? Inicia tu sesión para editar o crear tus apps.",
          {button: "Inicia tu sesión", external: `${marketplace}/fihogar/login?source=${source}`}
        ]
      },
      {
        title: '2. Acceso a Token/secret key',
        texts:[
          '¿De qué se trata?',
          'El token es una credencial única y privada que nos permitirá identificar tu aplicación dentro del portal de una manera segura.',
          'El secret key es el acceso a la documentación de nuestras APIs privadas.',
          'Para obtener el acceso debes completar tu registro y enviar los siguientes documentos para la firma del acuerdo de confidencialidad (NDA) con Banco Fihogar',
          {olist: ['Acta constitutiva','Identificación responsable legal','Comprobante de domicilio','Registro fiscal']},
          'Una vez que completes la información tendrás acceso a la documentación de nuestras APIs que podrás testear frente a nuestro simulador bancario BankBot'
        ]
      },
      {
        title: '3. Suscríbete a nuestro Sandbox',
        texts:[
          'Ahora empieza la parte divertida.',
          'Nuestra documentación de APIs te enseñara como se pueden formatear tus llamadas HTTPS. Completa la información solicitada  podrás empezar a construir  tu aplicación a partir de data en un ambiente controlado. '
        ]
      },
      {
        title: '4. Haz tu primer API Call',
        texts:[
          'Felicitaciones! Al finalizar la etapa de testeo en el sandbox, nuestro equipo te dará todo el soporte que necesitas para llevar la aplicación a producción.'
        ]
      }
    ]
  }
}
