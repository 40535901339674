import React from 'react';
import APICategory from './components/APICategory.js'
import './styles/catalog.css';


class Catalog extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      cardFront: true,
      selectedAPIs: []
    }
    this.flipCard = this.flipCard.bind(this);
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  flipCard(selected){
    let apiFronts = this.state.selectedAPIs.map(a => a)
    apiFronts.includes(selected)?
      apiFronts.splice(apiFronts.indexOf(selected), 1):
      apiFronts.push(selected)
    this.setState({cardFront: !this.state.cardFront, selectedAPIs: apiFronts})
  }

  render(){
    const { data, language } = this.props

    return(
      <div>
        <div className="banner">
          <img src="/banner.svg" alt="banner"/>
          <p>{language === 'en'? 'CATALOG' : 'CATÁLOGO'}</p>
        </div>
        {data.map((c, i) =>
          <div key={i} className="category-section">
            <APICategory
              data={c}
              flipCard={this.flipCard}
              cardFront={this.state.cardFront}
              category={c.name}
              selectedAPIs={this.state.selectedAPIs}
              />
          </div>
        )}
      </div>
    )
  }
}

export default Catalog;
