
export default {
  en: {
    welcome: {
      image: '/welcome-pic.png',
      title: 'Welcome to Banco Fihogar for Developers',
      texts: [],
      subtitle: 'Let innovation reign free via our platform for developers '
    },
    cards: [
      {
        title: 'Browse APIs',
        text: 'Explore our catalogue',
        link: '/catalog',
        icon: '/icons/ico-orgchart.svg'
      },
      {
        title: 'Use Cases',
        text: 'Find yours here',
        link: '/use-cases',
        icon: '/icons/ico-idea.svg'
      },
      {
        title: 'Start Building',
        text: "Let's go!",
        link: '/build',
        icon: '/icons/ico-dev.svg'
      },
    ],
    slides: [
      {
        title: '',
        image: 'https://cdn.pixabay.com/photo/2017/08/01/23/23/people-2568603_1280.jpg',
        texts: [
          'Open Banking uses open APIs which allow external developers to create applications and services around the financial institution',
          'By working together and leveraging APIs, banks, fintechs and other service providers can leverage the synergies of an integrated ecosystem with the ultimate goal of providing a better user experience'
        ],
        buttonText: 'Browse APIs',
        buttonLink: '/catalog'
      },
      {
        title: '25 most innovative & transformative bankers',
        image: 'https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083382_1280.jpg',
        texts: [
          'We are excited to introduce our 2019 Top 25 Most Innovative Bankers in Latin America & the Caribbean.',
          'This year’s list of the bankers – and banks! - showcases a variety of ways that institutions are leveraging technology and innovation to transform for the digital age.'
        ],
        buttonText: '',
        buttonLink: ''
      },
    ],
    bottomBox:{
        title: 'Partner with Us',
        texts: [
          'Connect with new clients.',
          'Whether you are adding information of your account or creating a new payment solution, we make it easy for you to connect your innovative products with Fihogar bank customers',
          'It is easier than you think. Register today!'
        ],
        image: 'https://cdn.pixabay.com/photo/2015/02/02/11/09/office-620822_1280.jpg',
        buttonLink: '/build',
        buttonText: 'Start building'
      }
  },
  es: {
    welcome: {
      image: '/welcome-pic.png',
      title: 'Bienvenido a Banco Fihogar para Desarrolladores',
      texts: [],
      subtitle: 'Libera la Innovación a través de nuestra Plataforma para Desarrolladores'
    },
    cards: [
      {
        title: 'Ver APIs',
        text: 'Explora nuestro catálogo',
        link: '/catalog',
        icon: '/icons/ico-orgchart.svg'
      },
      {
        title: 'Casos de Uso',
        text: 'Encuentra el tuyo aquí',
        link: '/use-cases',
        icon: '/icons/ico-idea.svg'
      },
      {
        title: 'Comienza a Construir',
        text: 'Vamos!',
        link: '/build',
        icon: '/icons/ico-dev.svg'
      },
    ],
    slides: [
      {
        title: '',
        image: 'https://cdn.pixabay.com/photo/2017/08/01/23/23/people-2568603_1280.jpg',
        texts: [
          'Open Banking es el uso de APIs abiertas que permiten a desarrolladores externos construir aplicaciones y servicios alrededor de la Institución Financiera.',
          'Al trabajar juntos y aprovechar las APIs, los bancos, las fintech y otros proveedores de servicios pueden apalancar las sinergias de un ecosistema integrado con el objetivo final de proporcionar una mejor experiencia de usuario'],
        buttonText: 'Ver APIs',
        buttonLink: '/catalog'
      },
      {
        title: 'Los 25 banqueros más innovadores y transformadores',
        image: 'https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083382_1280.jpg',
        texts: [
          'Estamos muy entusiasmados de presentarte los 25 banqueros más Innovadores de Latino América y el Caribe del 2019.  La lista de banqueros y bancos de  este año  destaca la variedad de formas que instituciones están apalancando la tecnología y la innovación para transformarse para esta era digital. '
        ],
        buttonText: '',
        buttonLink: ''
      },
    ],
    bottomBox:{
        title: 'Asóciate con Nosotros',
        texts: [
          'Conéctate con nuevos clientes. Ya sea que estés agregando información de la cuenta o creando una nueva solución de pago, facilitamos la conexión de tus productos innovadores con los clientes de Banco Fihogar.',
          'Es más fácil de lo que crees. Regístrate hoy!'
        ],
        image: 'https://cdn.pixabay.com/photo/2015/02/02/11/09/office-620822_1280.jpg',
        buttonLink: '/build',
        buttonText: 'Empieza a construir'
      }
  },
}
