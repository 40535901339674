let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.finconecta.com"
let source  = process.env.REACT_APP_SOURCE || ""

export default {
  en: {
    mainTitle: 'ABOUT US',
    boxes: [
      {
        title: 'What is Banco Fihogar for Developers?',
        texts: [
          'Banco Fihogar for Developers offers direct access to an increasing number of  APIs, tools and technical support which help you build apps in a secure and efficient manner.'
        ],
        image: 'https://cdn.pixabay.com/photo/2015/01/02/00/01/telephone-586268_1280.jpg',
        buttonText: "Register Here",
        buttonLink: `${marketplace}/fihogar/register?source=${source}`
      },
      {
        title: 'Why use Banco Fihogar for Developers?',
        texts: [
          'Because  Bank Fihogar for Developers offers you direct access to a growing number of APIs, tools, and support that can help you start building easier, faster and more secure ways to power e-commerce.',
          'Our API catalogue allows for the development of applications, permits the complementation / modernization of  solutions, helps to quickly reach the market and exceed customer expectations.'
        ],
        image: 'https://cdn.pixabay.com/photo/2019/05/05/18/58/girl-4181395_1280.jpg',
        buttonText: 'Browse our API Catalogue',
        buttonLink: '/catalog'
      },
      {
        title: 'How to use Banco Fihogar for Developers?',
        texts: [
          'After identifying the relevant APIs that your project requires and completing your registration, you can test connectivity with our BankBot core banking simulator. Once the development and initial tests are completed, you can enter into our Sandbox and be able to run pilot tests in a safe environment. By having the certifications and authorization of Banco Fihogar for Developers, you can then go on to the production stage of the process.'
        ],
        image: 'https://cdn.pixabay.com/photo/2019/05/05/18/58/girl-4181395_1280.jpg',
        buttonText: 'Start building here',
        buttonLink: '/build'
      }
    ]
  },
  es: {
    mainTitle: 'CONÓCENOS',
    boxes: [
      {
        title: '¿Qué es Banco Fihogar para Desarrolladores?',
        texts: [
          'Banco Fihogar para Desarrolladores ofrece acceso directo a un creciente número de APIs, herramientas y soporte técnico que te ayudan construir aplicaciones de manera rápida y segura.'
        ],
        image: 'https://cdn.pixabay.com/photo/2015/01/02/00/01/telephone-586268_1280.jpg',
        buttonText: 'Regístrate Aquí',
        buttonLink: `${marketplace}/fihogar/register?source=${source}`
      },
      {
        title: '¿Por qué usar Banco Fihogar para desarrolladores?',
        texts: [
          'Nuestro  catálogo de APIs permite desarrollar aplicaciones, complementar/modernizar soluciones, llegar rápidamente al mercado y sobrepasar las expectativas del consumidor.'
        ],
        image: 'https://cdn.pixabay.com/photo/2019/05/05/18/58/girl-4181395_1280.jpg',
        buttonText: 'Ver Catálogo de APIs aquí',
        buttonLink: '/catalog'
      },
      {
        title: '¿Cómo usar Banco Fihogar para Desarrolladores?',
        texts: [
          'Después de identificar las APIs relevantes que tu proyecto requiere y completar tu registro podrás testear la conectividad con nuestro simulador de core bancario BankBot. Una vez que concluya el desarrollo y los test  iniciales, podrás entrar en nuestro Sandbox para correr pruebas piloto en un ambiente seguro. Al contar con las certificaciones y la autorización de Banco Fihogar para Desarrolladores, podrás pasar a la etapa de producción.'
        ],
        image: 'https://cdn.pixabay.com/photo/2019/05/05/18/58/girl-4181395_1280.jpg',
        buttonText: 'Empieza a construir aquí',
        buttonLink: '/build'
      }
    ]
  },
}
