import React from 'react';
import API from './API.js'
import Slider from './Slider.js'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1440 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 1440, min: 1390 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1390, min: 748 },
    items: 3
  },
  tablet2: {
    breakpoint: { max: 1096, min: 748 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 748, min: 0 },
    items: 1
  }
};

const logoMap = {
  Accounts: "Accounts.png-1587659587258",
  Authorize: "Authorization Access.png-1587757729901",
  Beneficiaries: "Benefitiaries.png-1587659400101",
  Cards: "Accounts.png-1587659608944",
  Deposits: "Deposits.png-1587659317226",
  'Exchange Rates': "Deposits.png-1587659317226",
  Logistics: "Logistics.png-1587659444638",
  Notifications: "Notifications.png-1587926433613",
  Payments: "Payments.png-1587659644439",
  PIN: "PIN.png-1587659041840",
  Pin: "PIN.png-1587659041840",
  Profile: "Profile.png-1587659016020",
  Ramp: "Deposits.png-1587659317226",
  Token: "Token.png-1587757055682",
  Transfers: "Transfers.png-1587659626374",
  Withdrawals: "Withdrawals.png-1587659350382"
}; // TODO: remove fixed images

const APICategory = (props)=>{
  const { data, flipCard, cardFront, category, selectedAPIs } = props
  const apiLogoId = logoMap[data.name] || "default.png";
  // console.log(category);
  return(
    <div className="category-container">
      <p className="category-title">{category}</p>
      <Slider>
        {
          data.resources.map((a, i) =>
          <div className="category-data" onClick={(e) => flipCard(`${category}-${i}`)} key={`${category}-${i}`}>
            <API
              data={a}
              cardFront={cardFront}
              clickedAPI={selectedAPIs.includes(`${category}-${i}`)? true : false}
              image={apiLogoId}
              />
          </div>
        )}
      </Slider>

    </div>
  )
}

export default APICategory


// {
//   data.apiList.map((a, i) =>
//   <div className="category-data-mobile" onClick={(e) => flipCard(`${category}-${i}`)} key={`${category}-${i}`}>
//     <API
//       data={a}
//       cardFront={cardFront}
//       clickedAPI={selectedAPIs.includes(`${category}-${i}`)? true : false}
//       />
//   </div>
// )}
